import styled from 'styled-components';
import { media, spacing, toRem } from '@styles/Theme';

import { Wrapper as LWrapper } from '@components/Layout/styles';

export const Wrapper = styled(LWrapper)`
	@media (${media.desktop}) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
`;

export const MainContent = styled.div`
	@media (${media.desktop}) {
		flex: 1;
		margin-right: ${spacing(4)};
	}
`;

export const InsuranceList = styled.ul`
	position: relative;
	display: grid;
	grid-gap: ${spacing(1)};
	grid-template-columns: repeat(2, 1fr);
	margin-top: ${spacing(5)};

	&::after {
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 100%;
		max-width: ${toRem(620)};
		transform: translateX(-50%);
		content: '';
	}

	@media (${media.tablet}) {
		grid-gap: ${spacing(2)};
		grid-template-columns: repeat(3, 1fr);
	}

	@media (${media.desktop}) {
		grid-gap: ${spacing(3)};
		grid-template-columns: repeat(3, 1fr);
	}
`;

export const InsuranceItem = styled.li`
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${toRem(120)};
	padding: ${spacing(2)};
	background-color: #fff;
	border-radius: ${spacing(2)};

	@media (${media.tablet}) {
		height: ${toRem(160)};
	}

	@media (${media.desktop}) {
		height: ${toRem(200)};
	}
`;

export const InsuranceImage = styled.img`
	display: block;
	max-width: calc(100vw / 3);
`;
