import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { H1, P } from '@styles/Typography';

import { Wrapper, Section } from '@components/Layout/styles';

import Breadcrumb from '@components/Breadcrumb';
import SEO from '@components/seo';
import Sidebar from '@components/Sidebars/Main';
import CTA from '@components/CTAS/Insurance';

import * as S from './_styles';

const ConveniosPage = () => {
	const { insurances, meta, pageData } = useStaticQuery(graphql`
		{
			meta: allCosmicjsMeta(filter: { slug: { eq: "meta-insurances" } }) {
				nodes {
					metadata {
						title
						description
						cover {
							imgix_url
						}
					}
				}
			}

			pageData: allCosmicjsPages(filter: { slug: { eq: "insurance" } }) {
				nodes {
					metadata {
						description
						title
					}
				}
			}

			insurances: allCosmicjsInsurances {
				nodes {
					metadata {
						name
						logo {
							imgix_url
						}
					}
				}
			}
		}
	`);

	const { title, description } = pageData.nodes[0].metadata;
	const insuranceList = insurances.nodes;

	const {
		metadata: { title: metaTitle, description: metaDescription, cover },
	} = meta.nodes[0];

	return (
		<>
			<SEO
				title={metaTitle || 'Convênios'}
				description={metaDescription}
				cover={cover.imgix_url}
			/>

			<Wrapper>
				<Breadcrumb crumbs={[{ title: 'Convênios', slug: 'convenios' }]} />
			</Wrapper>

			<Section>
				<S.Wrapper>
					<S.MainContent>
						<H1>{title}</H1>
						<P>{description}</P>

						<S.InsuranceList>
							{insuranceList.map(
								({
									metadata: {
										name,
										logo: { imgix_url },
									},
								}) => (
									<S.InsuranceItem key={name}>
										<S.InsuranceImage src={imgix_url} alt={name} />
									</S.InsuranceItem>
								)
							)}
						</S.InsuranceList>

						<CTA />
					</S.MainContent>

					<Sidebar />
				</S.Wrapper>
			</Section>
		</>
	);
};

export default ConveniosPage;
